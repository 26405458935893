@mixin contentContainer {
    padding: 20px;
    background: var(--neutral-grey-200);
    border-radius: 16px;
}

@mixin borderBottomContainer {
    border-bottom: 1px solid var(--neutral-grey-300);
    padding-bottom: 16px;
    display: flex;
}

@mixin horizontalContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@mixin verticalContainer {
    display: flex;
    flex-direction: column;
}

@mixin subTitleStyle {
    font-size: 18px;
    line-height: 20.7px;
    font-weight: 400;
    color: var(--urbanx-black);
}

@mixin baseFont {
    font-family: "Inter";
    font-style: normal;
}

@mixin mobile {
    @media (max-width: 699px) {
        @content;
    }
}

@mixin tabletPortrait {
    @media (min-width: 700px) and (max-width: 919px) {
        @content;
    }
}

@mixin tabletLandscape {
    @media (min-width: 920px) and (max-width: 1299px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1300px) {
        @content;
    }
}