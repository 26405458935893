.mobileHeaderContainer {
    height: calc(6rem + 1px);
    background: #000;
    position: fixed;
    padding: 0 1rem;
    z-index: 10;
}

.toggleMenuBtn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 20px;
    cursor: pointer;
    gap: 5px;
    background: #222222;
    padding: 15px;
    border-radius: 30px;
  }

.line {
    height: 2px;
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    border: 1px solid #fff;
  }

  .toggleMenuBtn.open .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg) translateX(1px);
  }
  
  .toggleMenuBtn.open .line:nth-child(2) {
    opacity: 0;
  }
  
  .toggleMenuBtn.open .line:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg) translateX(1px);
  }