/* Applying Brand Colors */
:root {
  --brand-primary-color: #f5f5f5;
  --brand-secondary-color: #000000;
  --brand-secondary-color-rgb: rgb(207, 169, 135);
  --brand-tertiary-color: rgba(255, 255, 255, 0.1);
  --brand-text-primary-color: #6a6a6a;
  --brand-text-button-color: #f5f5f5;
  --brand-notification-bubble-color: #fff;
  --brand-transparentA: rgba(46, 43, 43, 0.115);
  --brand-transparentB: rgba(255, 255, 255, 0.1);
  --brand-outline-color: #cfa98720;
  --brand-secondary-color-alpha90: #00000090;
  --urbanx-black: #000;
  --urbanx-white: #fff;
  --urbanx-orange: #e96e43;
  --form-optional-color: #989898;
  --form-input-border: #cdcdcd;
  --neutral-grey-100: #fafafa;
  --neutral-grey-200: #f5f5f5;
  --neutral-grey-300: #dfdfdf;
  --neutral-grey-400: #cdcdcd;
  --neutral-grey-500: #b9b9b9;
  --neutral-grey-600: #989898;
  --neutral-grey-700: #6a6a6a;
  --neutral-grey-800: #5b5b5b;
  --ui-error: #f94a3f;
  --form-padding: 16px; }

html,
body,
#root {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  background: var(--neutral-grey-200);
  scroll-behavior: smooth;
  display: flex;
  height: 100vh;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 16px; }

.app {
  background: var(--neutral-grey-200); }

/* Container to hold the layout */
.container {
  display: flex;
  position: relative;
  /* Parent element for layering */
  background-color: #111;
  /* Optional outer background */
  width: 100%; }
