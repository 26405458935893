@import "../../Mixins.scss";

/* ==========================
   Sidebar Styles
   ========================== */
.sidebar {
    width: 270px;
    background-color: #111;
    color: #fff;
    padding: 1rem;
    position: relative;
    transition: width 0.2s ease;
    z-index: 1;

    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;

    @include mobile {
      position: fixed;
      top: 6rem;
      bottom: 0;
      width: 100%;
      z-index: 10;
      left: 0;
      transition: left 0.3s ease;
      overflow-y: auto;
      max-height: calc(100vh - 6rem);
    }

    @include tabletPortrait {
        position: fixed;
        top: 6rem;
        bottom: 0;
        width: 270px;
        z-index: 10;
        left: 0;
        transition: left 0.3s ease;
        overflow-y: auto;
        max-height: calc(100vh - 6rem);
      }
}

.sidebar.collapsed {
    width: 60px;

    @include mobile {
      left: -1000px;
      width: 0px;
      transition: left 0.3s ease, width 0.3s ease;
    }
    @include tabletPortrait {
        left: -1000px;
        width: 0px;
        transition: left 0.3s ease, width 0.3s ease;
    }
}

/* ==========================
   Sidebar Header Styles
   ========================== */
.sidebar h2 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    white-space: nowrap;
}

/* ==========================
   Menu Styles
   ========================== */
.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    width: 270px;
    top: 0;
    left: 1rem;

    @include mobile {
      width: calc(100% - 2rem);
      transition: left 0.3s ease;
    }

    @include tabletPortrait {
        width: 270px;
        transition: left 0.3s ease;
    }
}

.menu.collapsed {
  @include mobile {
    left: -1000px;
    transition: left 0.3s ease;
  }

  @include tabletPortrait {
    left: -1000px;
    transition: left 0.3s ease;
  }
}

.menuShort {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 1rem;
    opacity: 0;
}

.menu-item {
    cursor: pointer;
    padding: 0.5rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1rem;
}

.menu-item-container {
    border-radius: 1rem;
}

/* ==========================
   Active Styles
   ========================== */
.submenu-item-active,
.menu-item-active {
    background: #222222;
    opacity: 1 !important;

    @include mobile {
      transition: opacity 0.3s ease;
    }
    @include tabletPortrait {
        transition: opacity 0.3s ease;
      }
}

.active {
    opacity: 1 !important;
}

.menu-item-text {
    opacity: 0.6;
}

/* ==========================
   Submenu Styles
   ========================== */
.submenu-item {
    cursor: pointer;
    padding: 1rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 2rem;
    margin-left: 1rem;
    border-radius: 1rem;
}

.submenu-item:hover,
.menu-item:hover {
    background: #222;
}

.has-submenu .submenu {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

.has-submenu.open[data-submenu-count="1"] .submenu {
    height: 50px;
    opacity: 1;
    transition: height 0.2s ease, opacity 0.2s ease;
}

.has-submenu.open[data-submenu-count="2"] .submenu {
    height: 100px;
    opacity: 1;
    transition: height 0.2s ease, opacity 0.2s ease;
}

.has-submenu.open[data-submenu-count="3"] .submenu {
    height: 150px;
    opacity: 1;
    transition: height 0.2s ease, opacity 0.2s ease;
}

.has-submenu.open[data-submenu-count="4"] .submenu {
    height: 200px;
    opacity: 1;
    transition: height 0.2s ease, opacity 0.2s ease;
}

.has-submenu.open[data-submenu-count="5"] .submenu {
    height: 250px;
    opacity: 1;
    transition: height 0.2s ease, opacity 0.2s ease;
}

.has-submenu .submenu li {
    opacity: 1;
}

.submenu li {
    list-style: none;
    margin-left: 2rem;
    border-left: 1px solid rgba(223, 223, 223, 0.3);
}

.submenu-active-text {
  opacity: 1 !important;
}

/* ==========================
   Icon and Container Styles
   ========================== */
.ic {
    display: flex;
    align-items: center;
}

.submenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    border-radius: 1rem;
}

.submenuContainer:hover {
    background: #222;
}

.has-submenu.open .submenuContainer .ic {
    transform: rotate(180deg);
}

/* ==========================
   Link Styles
   ========================== */
.linkStyle {
    color: #fff;
    text-decoration: none;
}

/* ==========================
   Collapse Button Styles
   ========================== */
.collapse-btn {
    position: absolute;
    top: 20%;
    left: 18rem;
    background-color: var(--neutral-grey-200);
    color: #000000;
    border: none;
    border-radius: 50%;
    border: 1px solid var(--neutral-grey-300);
    cursor: pointer;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    text-align: center;
    padding-left: 2px;
    z-index: 3;
    transition: left 0.2s ease;

    @include mobile {
      display: none;
    }
    @include tabletPortrait {
        display: none;
    }
}

.collapse-btn.collapsed {
    left: 5rem;
    transform: rotate(180deg) translateY(50%);
    transition: left 0.2s ease; 

    @include mobile {
      display: none;
    }
    @include tabletPortrait {
        display: none;
    }
}

.collapse-btn.expanded {
    left: 18rem;
    transform: translateY(-50%);
    transition: left 0.2s ease; 

    @include mobile {
      display: none;
    }
    @include tabletPortrait {
        display: none;
    }
}

/* ==========================
   Main Content Styles
   ========================== */
.main-content {
    flex-grow: 1;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
    z-index: 2;
    border-radius: 1rem;
    
    @include mobile {
        border-radius: 1rem 1rem 0 0;
    }
    @include tabletPortrait {
        border-radius: 1rem 1rem 0 0;
    }
    @include tabletLandscape {
        border-radius: 1rem 0 0 1rem;
    }
}

/* ==========================
   Collapsed State Styles
   ========================== */
.sidebar.collapsed .menu {
    opacity: 0;
    transition: opacity 0s ease;
}

.sidebar.collapsed .menuShort {
    opacity: 1;
}


/* =========================
    BACKDROP
   =========================*/
.backdrop {
    position: fixed;
    top: 6rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}  

.sidebar-expanded ~ .backdrop {
    opacity: 1;
    pointer-events: auto;
}