.campaignMarketing {
  .campaignIdLink {
    color: #318ce0;
    text-decoration: none;

    > span {
      line-height: 125%;
      font-weight: 400;
    }
  }

  .paymentStatusLabel {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 10px;

    border-radius: 51px;

    width: fit-content;

    &.awaitingFunds {
      background-color: #dcd4fe;
    }

    &.payLaterApplicationSigned,
    &.pendingSettlement,
    &.complete {
      background-color: #defafe;
    }

    &.paid {
      background-color: #ceffcc;
    }

    &.cancelled {
      background-color: #f5f5f5;
    }

    &.withdrawn,
    &.payLaterDeclined {
      background-color: #f1bc98;
    }
  }

  span.campaignMarketingAmount {
    color: var(--neutral-grey-800, #5b5b5b);
    line-height: 125%;
    font-weight: 400;
  }

  span.campaignFlowStatusInfo {
    color: var(--Neutral-Grey-700, #6a6a6a);
    line-height: 125%;
    font-weight: 300;
    font-size: 11px;
  }

  td {
    padding: 20px 0px;
  }

  th {
    padding: 16px 0px;

    > span.small {
      font-weight: 400;
      line-height: 125%;
    }

    &:first-child {
      padding-left: 8px;
    }

    &:last-child {
      padding-right: 8px;
    }
  }

  .marketingStatusRow {
    &:hover {
      background: var(--Neutral-Grey-100, #fafafa);
    }
  }

  .campaignMarketingTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th, td {
      padding-right: 20px;

      &:first-child {
        padding-left: 8px;
        width: 30%;
      }

      &:last-child {
        padding-right: 8px;
      }
    }

    svg {
      flex-shrink: 0;
    }
  }
}
